import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((_theme) => ({
  root: {
    backgroundImage: `url("https://res.cloudinary.com/pastisah-id/image/upload/v1716351970/Group_122_n1jlsh.png")`,
    width: "100%",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#fff",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "64px",
  },
  widthSection: {
    width: "656px",
  },
  divSectionLeft: {
    display: "flex",
    flexDirection: "column",
    height: "540px",
    justifyContent: "center",
  },
  pLeft: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%" /* 63px */,
    letterSpacing: "-0.924px",
    textAlign: "left",
    margin: 0,
  },
  colorPLogin: {
    color: "#00539B",
  },
  divSectionRight: {
    borderRadius: "12px",
    background: "#FFF",
    boxShadow:
      "0px 0px 20px 0px rgba(207, 223, 236, 0.25), 0px 0px 20px 0px rgba(207, 229, 246, 0.25)",
    width: "460px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "40px",
    position: "relative",
    alignItems: "center",
  },
  widthLogoRight: {
    width: "185px",
  },
  pTitleSSO: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    letterSpacing: "-0.456px",
    textAlign: "center",
  },
  labelField: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
    letterSpacing: "-0.176px",
    margin: 0,
    marginBottom: "5px",
  },
  styleField: {
    width: "450px",
    margin: 0,
  },
  styleFieldBorder: {
    borderColor: "#CFDFEC",
    borderWidth: "2px",
    borderRadius: "12px",
  },
  marginBottom15: {
    marginBottom: "15px",
  },
  pfooter: {
    color: "#3C3C3C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "-0.084px",
    paddingBottom: "20px",
  },
  buttonGenerate: {
    marginTop: "10px",
    borderRadius: "12px",
    background: "#00539B",
    display: "flex",
    padding: "12px 22px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    alignSelf: "stretch",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
    letterSpacing: "-0.176px",
    textTransform: "capitalize !important",
  },
  stlLogo: {
    width: "220px",
  },
  stlDivQr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pPindaiQr: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
    letterSpacing: "-0.176px",
  },
  borderImgQr: {
    borderRadius: "10.633px",
    border: "0.886px solid #CFDFEC",
    background: "#FFF",
  },
  sizeImgQr: {
    padding: "5px",
    width: "244px",
    height: "244px",
  },
  blurImg: {
    padding: "5px",
    width: "244px",
    height: "244px",
    filter: "blur(10px)",
    webkitFilter: "blur(10px)",
  },
  divTextNotes: {
    borderRadius: "12px",
    border: "1px solid #CFDFEC",
    padding: "15px",
    marginTop: "32px",
  },
  tltNotes: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%" /* 24px */,
    letterSpacing: "-0.176px",
  },
  descQr: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%" /* 24px */,
    letterSpacing: " -0.176px",
    margin: 0,
  },
  descQr2: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%" /* 24px */,
    letterSpacing: " -0.176px",
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  iconButtonQr: {
    width: "25px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  "@media (min-width: 0px) and (max-width: 390px)": {
    stlLogo: {
      width: "180px",
    },
    body: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "32px",
    },
    pLeft: {
      color: "#3C3C3C",
      fontFamily: "Inter",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%" /* 63px */,
      letterSpacing: "-0.924px",
      textAlign: "left",
      margin: 0,
    },
    widthSection: {
      width: "100%",
    },
    divSectionRight: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow:
        "0px 0px 20px 0px rgba(207, 223, 236, 0.25), 0px 0px 20px 0px rgba(207, 229, 246, 0.25)",
      width: "250px",
      padding: "28px",
      display: "flex",
      flexDirection: "column",
      marginLeft: 0,
      position: "relative",
    },
    styleField: {
      width: "250px",
      margin: 0,
    },
  },
  "@media (min-width: 391px) and (max-width: 767px)": {
    stlLogo: {
      width: "180px",
    },
    body: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "32px",
    },
    pLeft: {
      color: "#3C3C3C",
      fontFamily: "Inter",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%" /* 63px */,
      letterSpacing: "-0.924px",
      textAlign: "left",
      margin: 0,
    },
    widthSection: {
      width: "100%",
    },
    divSectionRight: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow:
        "0px 0px 20px 0px rgba(207, 223, 236, 0.25), 0px 0px 20px 0px rgba(207, 229, 246, 0.25)",
      width: "280px",
      padding: "28px",
      display: "flex",
      flexDirection: "column",
      marginLeft: 0,
      position: "relative",
    },
    styleField: {
      width: "275px",
      margin: 0,
    },
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    body: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "32px",
    },
    stlLogo: {
      width: "180px",
    },
    pLeft: {
      color: "#3C3C3C",
      fontFamily: "Inter",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%" /* 63px */,
      letterSpacing: "-0.924px",
      textAlign: "left",
      margin: 0,
    },
    styleField: {
      width: "350px",
      margin: 0,
    },
    divSectionRight: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow:
        "0px 0px 20px 0px rgba(207, 223, 236, 0.25), 0px 0px 20px 0px rgba(207, 229, 246, 0.25)",
      width: "360px",
      padding: "28px",
      display: "flex",
      flexDirection: "column",
      marginLeft: "40px",
      position: "relative",
    },
  },
}));

export default useStyles;
