import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((_theme) => ({
  divHeader: {
    borderBottom: "1px solid #BDE8DC",
    padding: "16px 28px",
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  stlLogo: {
    height: "95px",
    marginLeft: "15px",
  },
  pEN: {
    color: "#707070",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
    letterSpacing: "-0.176px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  marginAvatar: {
    marginLeft: "10px",
    marginRight: "5px",
  },
  divDrawer: {
    width: "250px",
    height: "800px",
    borderRight: "1px solid #BDE8DC",
    background: "#FFF",
    padding: "10px 16px 0 16px",
  },
  drawerActive: {
    padding: "8px 12px",
    borderRadius: "6px",
    background: "#E0EFED",
  },
  drawerNonActive: {
    padding: "8px 12px",
  },
  divSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "30px",
  },
  pTitleActive: {
    color: "#00539B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "-0.176px",
    marginLeft: "20px",
  },
  pTitleNonActive: {
    color: "#3c3c3c",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "-0.176px",
    marginLeft: "20px",
  },
  divRight: {
    display: "inline-flex",
    padding: "30px 32px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  pWelcome: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "-0.456px",
    margin: 0,
  },
  pDate: {
    color: "#707070",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "-0.176px",
    margin: 0,
  },
  pLayanan: {
    color: "#3C3C3C",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%" /* 27px */,
    letterSpacing: "-0.252px",
  },
  flexAlignCenterLayanan: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  boxLayanan: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "12px",
    border: "1px solid #CFDFEC",
    background: "#FFF",
    cursor: "pointer",
  },
  sizeIconLayanan: {
    width: "60px",
  },
  pJenisLayanan: {
    color: "#3C3C3C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%" /* 21px */,
    letterSpacing: "-0.084px",
  },
  "@media (min-width: 0px) and (max-width: 390px)": {
    divDrawer: {
      width: "45px",
      height: "120vh",
      borderRight: "1px solid #BDE8DC",
      background: "#FFF",
      padding: "10px 16px 0 16px",
    },
    pTitleActive: {
      display: "none",
      color: "#00539B",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "-0.176px",
      marginLeft: "20px",
    },
    pTitleNonActive: {
      display: "none",
      color: "#3c3c3c",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "-0.176px",
      marginLeft: "20px",
    },
    flexAlignCenterLayanan: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },
  },
  "@media (min-width: 391px) and (max-width: 767px)": {
    divDrawer: {
      width: "45px",
      height: "120vh",
      borderRight: "1px solid #BDE8DC",
      background: "#FFF",
      padding: "10px 16px 0 16px",
    },
    pTitleActive: {
      display: "none",
      color: "#00539B",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "-0.176px",
      marginLeft: "20px",
    },
    pTitleNonActive: {
      display: "none",
      color: "#3c3c3c",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "-0.176px",
      marginLeft: "20px",
    },
    flexAlignCenterLayanan: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    divDrawer: {
      width: "250px",
      height: "100vh",
      borderRight: "1px solid #BDE8DC",
      background: "#FFF",
      padding: "10px 16px 0 16px",
    },
  },
}));

export default useStyles;
