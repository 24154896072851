import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import RequestHttp from "../generals/helpers/RequestHttp";
import moment from "moment";
import socketIO from "../generals/helpers/socketio";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CountDownTimer from "../generals/assets/Component/CountDownTimer";

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const timeReGenerate = { hours: 0, minutes: 2, seconds: 0 };
  let timer;
  const [value, setValue] = useState({
    merchant_apikey: "45aeaf",
    merchant_secret_key: "090f24",
    merchant_id: "MCP01",
    callback_url: `${process.env.REACT_APP_URL_INAPASS_BE}sso/qr/test-callback`,
    // merchant_apikey: "f0e83c",
    // merchant_secret_key: "cf5ca8",
    // merchant_id: "TEST1",
    // callback_url:
    //   "https://37e5-2001-448a-2040-87a0-89e7-784d-7855-d30c.ngrok-free.app/sso/qr/test-callback",
    order_id: "",
    img_qr: "",
    isLoadingGenerate: false,
  });
  const [doneGenerated, setDoneGenerated] = useState(false);
  const [openNotif, setOpenNotif] = useState({
    open: false,
    status: "",
    message: "",
  });
  const vertical = "bottom";
  const horizontal = "center";

  useEffect(() => {
    if (doneGenerated === true && value?.order_id !== "") {
      socketIO.on(`${value.order_id}`, () => {
        // go to dashboard
        navigate("/dashboard");
        clearTimeout(timer);
        // set done generated false
        setDoneGenerated(false);
      });

      return () => {
        socketIO.off(`${value.order_id}`, () => {});
      };
    }
  }, [doneGenerated, navigate, value.order_id]);

  // Reconnect the socket if it gets disconnected
  useEffect(() => {
    if (socketIO && socketIO.disconnected) {
      socketIO.connect();
    }
  }, []);

  const generateLink = () => {
    let error = [];
    if (value.callback_url === "") {
      error.push("err");
      setOpenNotif({
        open: true,
        status: "error",
        message: "Callback Url Wajib Diisi",
      });
    }
    if (value.order_id === "") {
      error.push("err");
      setOpenNotif({
        open: true,
        status: "error",
        message: "Order Id Wajib Diisi",
      });
    }
    if (value.merchant_id === "") {
      error.push("err");
      setOpenNotif({
        open: true,
        status: "error",
        message: "Merchant Id Wajib Diisi",
      });
    }
    if (value.merchant_secret_key === "") {
      error.push("err");
      setOpenNotif({
        open: true,
        status: "error",
        message: "Merchant Secret Key Wajib Diisi",
      });
    }
    if (value.merchant_apikey === "") {
      error.push("err");
      setOpenNotif({
        open: true,
        status: "error",
        message: "Merchant Apikey Wajib Diisi",
      });
    }
    if (error.length === 0) {
      setValue({ ...value, isLoadingGenerate: true });
      const timestamp = moment().toString();
      const merchant_id = value.merchant_id;
      const merchant_apikey = value.merchant_apikey;
      const merchant_secret_key = value.merchant_secret_key;
      const hmac_xsecret = merchant_id + merchant_apikey + timestamp;
      const hmac_result = CryptoJS.HmacSHA256(
        hmac_xsecret,
        merchant_secret_key
      ).toString(CryptoJS.enc.Base64);
      const merchant_x_secret_key = hmac_result;
      const fd = new FormData();
      fd.append(`orderId`, value.order_id);
      fd.append(`timeStamp`, timestamp);
      fd.append(`callbackUrl`, value.callback_url);
      RequestHttp.post(`sso/qr/image`, fd, {
        headers: {
          timestamp: timestamp,
          "merchant-id": merchant_id,
          "x-api-key": merchant_apikey,
          "x-secret-key": merchant_x_secret_key,
        },
      })
        .then((response) => {
          setValue({
            img_qr: response.data.data,
            merchant_apikey: value.merchant_apikey,
            merchant_secret_key: value.merchant_secret_key,
            merchant_id: value.merchant_id,
            order_id: value.order_id,
            callback_url: value.callback_url,
            isLoadingGenerate: false,
          });
          timer = setTimeout(() => {
            reGenerate();
          }, 120000);
          setDoneGenerated(true);
        })
        .catch((err) => {
          setDoneGenerated(false);
          setValue({ ...value, isLoadingGenerate: false });
          setOpenNotif({
            open: true,
            status: "error",
            message: err.response.data.response_message,
          });
        });
    }
  };

  const reGenerate = () => {
    let orderId = (Math.random() + 1).toString(36).substring(7);
    setValue({ ...value, isLoadingGenerate: true });
    const timestamp = moment().toString();
    const merchant_id = value.merchant_id;
    const merchant_apikey = value.merchant_apikey;
    const merchant_secret_key = value.merchant_secret_key;
    const hmac_xsecret = merchant_id + merchant_apikey + timestamp;
    const hmac_result = CryptoJS.HmacSHA256(
      hmac_xsecret,
      merchant_secret_key
    ).toString(CryptoJS.enc.Base64);
    const merchant_x_secret_key = hmac_result;
    const fd = new FormData();
    fd.append(`orderId`, orderId);
    fd.append(`timeStamp`, timestamp);
    fd.append(`callbackUrl`, value.callback_url);
    RequestHttp.post(`sso/qr/image`, fd, {
      headers: {
        timestamp: timestamp,
        "merchant-id": merchant_id,
        "x-api-key": merchant_apikey,
        "x-secret-key": merchant_x_secret_key,
      },
    })
      .then((response) => {
        setValue({
          img_qr: response.data.data,
          merchant_apikey: value.merchant_apikey,
          merchant_secret_key: value.merchant_secret_key,
          merchant_id: value.merchant_id,
          order_id: orderId,
          callback_url: value.callback_url,
          isLoadingGenerate: false,
        });
        timer = setTimeout(() => {
          reGenerate();
        }, 120000);
        setDoneGenerated(true);
      })
      .catch((err) => {
        setDoneGenerated(false);
        setValue({ ...value, isLoadingGenerate: false });
        setOpenNotif({
          open: true,
          status: "error",
          message: err.response.data.response_message,
        });
      });
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotif({
      open: false,
      status: "",
      message: "",
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Snackbar
          open={openNotif ? openNotif.open : false}
          autoHideDuration={3000}
          onClose={handleClose}
          key={vertical + horizontal}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert
            onClose={handleClose}
            severity={openNotif?.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {openNotif?.message}
          </Alert>
        </Snackbar>
        <div className={classes.body}>
          <div className={classes.widthSection}>
            <img
              src="https://res.cloudinary.com/pastisah-id/image/upload/v1716795640/Logo_Kemenkes__Landscape_uag0bg.png"
              alt="logo"
              className={classes.stlLogo}
            />
            <div className={classes.divSectionLeft}>
              <p className={classes.pLeft}>
                Satu <span className={classes.colorPLogin}>Login</span>
              </p>
              <p className={classes.pLeft}>
                Akses semua layanan dengan mudah dalam satu Aplikasi.
              </p>
            </div>
          </div>
          <div className={classes.widthSection}>
            <div className={classes.divSectionRight}>
              <img
                src="https://res.cloudinary.com/pastisah-id/image/upload/v1715757409/Group_121_qaqat0.png"
                alt="logo"
                className={classes.widthLogoRight}
              />
              <p className={classes.pTitleSSO}>SSO Single Sign On</p>
              {doneGenerated ? (
                <>
                  <div className={classes.stlDivQr}>
                    <p className={classes.pPindaiQr}>
                      Pindai kode QR ini melalui aplikasi INAPass.
                    </p>
                    <div className={classes.borderImgQr}>
                      <img
                        src={value.img_qr}
                        // src="https://res.cloudinary.com/pastisah-id/image/upload/v1716367333/grommet-icons_qr_yo0lau.png"
                        alt="qr_login"
                        className={
                          value.isLoadingGenerate
                            ? classes.blurImg
                            : classes.sizeImgQr
                        }
                      />
                    </div>
                    <CountDownTimer hoursMinSecs={timeReGenerate} />
                    <div className={classes.divTextNotes}>
                      <p className={classes.tltNotes}>
                        Cara melakukan sinkronisasi menggunakan SSO INAPass.
                      </p>
                      <p className={classes.descQr}>
                        1. Buka Aplikasi mobile INAPass di Handphone.
                      </p>
                      <p className={classes.descQr2}>
                        2. Tap QR Reader
                        <span>
                          <img
                            src="https://res.cloudinary.com/pastisah-id/image/upload/v1716370464/Frame_53143_hths5i.png"
                            alt="qr"
                            className={classes.iconButtonQr}
                          />
                        </span>
                        dan pindai kode QR.
                      </p>
                      <p className={classes.descQr}>
                        3. Arahkan kamera ke kode QR untuk melakukan pemindaian.
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.marginBottom15}>
                    <p className={classes.labelField}>Merchant Apikey</p>
                    <TextField
                      value={value.merchant_apikey}
                      variant="outlined"
                      size="small"
                      onChange={(e) =>
                        setValue({ ...value, merchant_apikey: e.target.value })
                      }
                      className={classes.styleField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CFDFEC",
                            borderWidth: "2px",
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className={classes.marginBottom15}>
                    <p className={classes.labelField}>Merchant Secret Key</p>
                    <TextField
                      value={value.merchant_secret_key}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          merchant_secret_key: e.target.value,
                        })
                      }
                      id="outlined-size-small"
                      size="small"
                      className={classes.styleField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CFDFEC",
                            borderWidth: "2px",
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className={classes.marginBottom15}>
                    <p className={classes.labelField}>Merchant Id</p>
                    <TextField
                      value={value.merchant_id}
                      onChange={(e) =>
                        setValue({ ...value, merchant_id: e.target.value })
                      }
                      id="outlined-size-small"
                      size="small"
                      className={classes.styleField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CFDFEC",
                            borderWidth: "2px",
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className={classes.marginBottom15}>
                    <p className={classes.labelField}>Order Id</p>
                    <TextField
                      value={value.order_id}
                      onChange={(e) =>
                        setValue({ ...value, order_id: e.target.value })
                      }
                      id="outlined-size-small"
                      size="small"
                      className={classes.styleField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CFDFEC",
                            borderWidth: "2px",
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className={classes.marginBottom15}>
                    <p className={classes.labelField}>Callback Url</p>
                    <TextField
                      value={value.callback_url}
                      onChange={(e) =>
                        setValue({ ...value, callback_url: e.target.value })
                      }
                      id="outlined-size-small"
                      size="small"
                      className={classes.styleField}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CFDFEC",
                            borderWidth: "2px",
                            borderRadius: "12px",
                          },
                        },
                      }}
                    />
                  </div>
                  <Button
                    onClick={generateLink}
                    variant="contained"
                    disabled={value.isLoadingGenerate}
                    className={classes.buttonGenerate}
                    sx={{
                      borderRadius: "15px",
                    }}
                  >
                    Generate Link
                    {value.isLoadingGenerate && (
                      <CircularProgress
                        size={"1rem"}
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <p className={classes.pfooter}>©2024 - Pemerintah Indonesia</p>
        </div>
      </div>
    </>
  );
};

export default HomePage;
